import React from 'react';
import {
  Added,
  Improved,
  Fixed,
  Removed,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="April 2020" subnav="release-notes">
      <div id="April2020" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          It's the end of April and you{' '}
          <a className="uni-link uni-link--article" href="/rn-2020-04.webp">
            know what that means
          </a>
          . It's time for our first monthly V4 release!
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                Custom scrollbars that play nice in dark and light themes.
              </Added>
              <Improved>
                Our favicon so that you can actually see it when you're in dark
                mode.
              </Improved>
              <Improved>
                How we detail the <code>qaId</code>
                's in components.
              </Improved>
              <Removed>
                The Other tab on the code side. We don't think you'll miss it.
              </Removed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.1.0"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v4.1.0"
            />

            <div className={styles.changesList}>
              <Added>
                Tooltip support for the{' '}
                <Link href="/components/action-bar/design">action bar</Link> and
                other components.
              </Added>
              <Added>
                A <code>style</code> prop to all of our{' '}
                <Link href="/components/themes/design">theme</Link> components.
              </Added>
              <Improved>
                <Link href="/components/buttons/button/design/">Button</Link>{' '}
                styling when included in a Link wrapper for{' '}
                <Link href="/patterns/empty-states">empty states</Link>.
              </Improved>
              <Improved>
                The <Link href="/components/forms/select/design">select</Link>{' '}
                styling to support portaling and filter the <code>qaId</code>{' '}
                correctly.
              </Improved>
              <Improved>
                The select styling for expanded lists in different orientations
                and option alignment.
              </Improved>
              <Improved>
                <Link href="/components/tooltip/design">Tooltip</Link> support
                for showing and hiding.
              </Improved>
              <Fixed>
                <Link href="/components/notifications/toast/design">
                  Toasts
                </Link>{' '}
                so they behave correctly in a provider context.
              </Fixed>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
